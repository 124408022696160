import type { FC } from 'react';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../utils/appContext';

interface registeruserProps {
  refresh: () => void;
}

const RegisterUser: FC<registeruserProps> = ({ refresh }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [organisations, setOrganisations] = useState<Array<any>>([]);
  const [contacts, setContacts] = useState<Array<any>>([]);
  const [selectedOrganisations, setSelectedOrganisations] = useState<any>([]);
  const [selectedContact, setSelectedContact] = useState('');

  const context = useContext(AppContext);
  const navigate = useNavigate();

  const refreshList = () => {
    refresh();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    const organisations = selectedOrganisations;
    const contact = selectedContact;
    const storedJWT = localStorage.getItem('jwt');

    if (storedJWT) {
      await fetch('https://client-api.donkeycomm.be/register-user.php', {
        body: JSON.stringify({
          email,
          firstname,
          lastname,
          phone,
          organisations,
          contact,
        }),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + storedJWT,
        },
        method: 'POST',
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message == 'User registered successfully') {
            setSuccess(data.message);
            clearForm();
            refreshList();
          } else if (data.error == 'Email already exists') {
            setError('User already registered');
          }
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setError('Failed, try again');
          setLoading(false);
        });
    } else {
      console.log('not logged in');
      localStorage.clear();
      context?.updateLoginStatus(false);
      navigate('/login');
    }
  };

  const getAllOrganisations = async () => {
    const storedJWT = localStorage.getItem('jwt');
    await fetch('https://client-api.donkeycomm.be/get-all-organisations.php', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + storedJWT,
      },
    })
      .then((response) => response.json())
      .then((data: any) => {
        setOrganisations(data);
      })
      .catch((error) => {
        // Handle network or server errors
        console.log('error');
        console.log(error);
      });
  };

  const getContacts = async () => {
    const storedJWT = localStorage.getItem('jwt');
    await fetch('https://client-api.donkeycomm.be/get-contacts.php', {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + storedJWT,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setContacts(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const clearForm = () => {
    setEmail('');
    setFirstname('');
    setLastname('');
    setPhone('');
    setSelectedOrganisations([]);
    setSelectedContact('');
  };

  useEffect(() => {
    getAllOrganisations();
    getContacts();
  }, []);

  return (
    <>
      <h2 className="mt-12 text-xl font-normal text-gray-900">
        Register a user
      </h2>

      <div className="max-w-full pr-5 md:mt-8 md:max-w-lg">
        <div className="py-8 bg-white md:shadow md:px-4 md:rounded-lg ">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="grid gap-5 md:grid-cols-2">
              <div>
                <label
                  htmlFor="firstname"
                  className="block text-sm font-medium text-gray-700"
                >
                  Firstname
                </label>
                <div className="mt-1">
                  <input
                    id="firstname"
                    name="firstname"
                    type="text"
                    required
                    value={firstname}
                    onChange={(event) => setFirstname(event.target.value)}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="lastname"
                  className="block text-sm font-medium text-gray-700"
                >
                  Lastname
                </label>
                <div className="mt-1">
                  <input
                    id="lastname"
                    name="lastname"
                    type="text"
                    required
                    value={lastname}
                    onChange={(event) => setLastname(event.target.value)}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="grid gap-5 md:grid-cols-2">
              <div>
                <label
                  htmlFor="firstname"
                  className="block text-sm font-medium text-gray-700"
                >
                  E-mail
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    autoComplete="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone
                </label>
                <div className="mt-1">
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    required
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="role"
                className="block text-sm font-medium text-gray-700"
              >
                Organisations
              </label>
              <div className="mt-1">
                <select
                  id="organisations"
                  name="organisations"
                  required
                  multiple
                  value={selectedOrganisations}
                  onChange={(event) => {
                    // convert selected options to an array and update state
                    const selectedOptions = Array.from(
                      event.target.selectedOptions,
                      (option) => option.value
                    );
                    setSelectedOrganisations(selectedOptions);
                  }}
                  className={`text-default block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                >
                  <option disabled value="">
                    Select organisations
                  </option>

                  {Array.isArray(organisations) &&
                    organisations.map((organisation) => (
                      <option key={organisation.ID} value={organisation.ID}>
                        {organisation.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div>
              <label
                htmlFor="contact"
                className="block text-sm font-medium text-gray-700"
              >
                Contact
              </label>
              <div className="mt-1">
                <select
                  id="contact"
                  name="contact"
                  required
                  value={selectedContact}
                  onChange={(event) => setSelectedContact(event.target.value)}
                  className={`text-default block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                >
                  <option disabled value="">
                    Select a contact
                  </option>
                  <option value="0">No contact</option>
                  {Array.isArray(contacts) &&
                    contacts.map((contact) => (
                      <option key={contact.ID} value={contact.ID}>
                        {contact.firstname} {contact.lastname} - {contact.email}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div>
              {loading ? (
                <div className="flex justify-center">
                  <svg
                    className="inline-blockmt-5 w-9 h-9 text-accent animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              ) : (
                <button
                  type="submit"
                  className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Register
                </button>
              )}
              <p className="mt-2 text-sm font-medium text-red-600">{error}</p>
              <p className="mt-2 text-sm font-medium text-green-600">
                {success}
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RegisterUser;
