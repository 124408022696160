//create a react functional component in typescript
import { useEffect, useState, type FC } from 'react';

import Organisation from './organisation';

interface organisationListProps {
  refreshCounter: number;
}

const OrganisationList: FC<organisationListProps> = ({ refreshCounter }) => {
  const [organisations, setOrganisations] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);

  const getOrganisations = async () => {
    setLoading(true);

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');

    user.email &&
      (await fetch(
        'https://client-api.donkeycomm.be/get-all-organisations.php',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + storedJWT,
          },
          body: JSON.stringify({
            email: user.email,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setOrganisations(data);
          setLoading(false);
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          setLoading(false);
        }));
  };

  //create the function to create a folder

  useEffect(() => {
    getOrganisations();
  }, [refreshCounter]);

  return (
    <div className="min-h-[300px] py-10 pr-3 ">
      {loading ? (
        <div className="flex justify-center">
          <svg
            className="inline-blockmt-5 w-9 h-9 text-accent animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      ) : (
        <div className="max-h-[60vh] max-w-full relative overflow-x-scroll border-b border-slate-100 pb-5">
          <div className="grid grid-cols-2 min-w-[800px]">
            <div>
              <p className="px-3 py-2 text-xs font-bold uppercase border-b border-gray-200">
                Name
              </p>
            </div>
            <div className="border-b border-gray-200"></div>
          </div>
          {Array.isArray(organisations) &&
            organisations.map((organisation) => {
              return (
                <Organisation
                  key={organisation.ID}
                  organisation={organisation}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};
export default OrganisationList;
