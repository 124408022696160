import type { FC } from 'react';
import { useState, useEffect } from 'react';

const ContactInfo: FC = () => {
  const [contact, setContact] = useState({});
  const getContact = async () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');
    user.email &&
      (await fetch('https://client-api.donkeycomm.be/get-contact-by-id.php', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + storedJWT,
        },
        body: JSON.stringify({
          id: user.contact,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setContact(data);
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
        }));
  };
  useEffect(() => {
    getContact();
  }, []);
  return (
    <>
      {Array.isArray(contact) && contact.length > 0 && (
        <div className="grid gap-3 md:p-5">
          <p className="mb-2 text-xs font-bold text-gray-400 uppercase ">
            NEED HELP?
          </p>
          <p className="mb-2 text-sm">
            {contact[0].firstname} {contact[0].lastname}
          </p>
          <p className="mb-2 text-xs ">{contact[0].title}</p>
          <a
            href={`mailto:${contact[0].email}`}
            className="flex mb-2 text-xs hover:text-indigo-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
              />
            </svg>
            <span>{contact[0].email}</span>
          </a>
          <a
            href={`tel:${contact[0].phone}`}
            className="flex mb-2 text-xs hover:text-indigo-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
              />
            </svg>
            <span>{contact[0].phone}</span>
          </a>
        </div>
      )}
    </>
  );
};

export default ContactInfo;
