import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AppContext from '../utils/appContext';
import Icon from '../components/icons';
import ContactInfo from '../components/contactinfo';
const Navigation = () => {
  const context = useContext(AppContext);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );

  const [navigation, setNavigation] = useState([
    { name: 'Dashboard', href: '/', icon: 'home' },
    { name: 'Files', href: '/files', icon: 'files' },
  ]);
  const [activePage, setActivePage] = useState('');

  useEffect(() => {
    setActivePage(location.pathname.split('/')[1]);
  }, [location]);

  const roles = JSON.parse(user.organisations || '[]');

  useEffect(() => {
    const newNavigation = [
      { name: 'Dashboard', href: '/', icon: 'home' },
      { name: 'Files', href: '/files', icon: 'files' },
    ];

    if (roles.includes(0) || roles.includes(1)) {
      //push two items to the array

      newNavigation.push(
        {
          name: 'Members',
          href: '/members',
          icon: 'members',
        },
        { name: 'Contacts', href: '/contacts', icon: 'contact' },
        {
          name: 'Organisations',
          href: '/organisations',
          icon: 'organisations',
        }
      );
    }

    if (roles.includes(0)) {
      newNavigation.push({ name: 'Bin', href: '/bin', icon: 'bin' });
    }

    setNavigation(newNavigation);
    setShow(true);
  }, []);

  const logout = () => {
    localStorage.clear();
    context?.updateLoginStatus(false);
    navigate('/login');
  };
  return (
    show && (
      <div className="grid content-between lg:min-h-[500px]">
        <div className="top">
          <div className="px-2 md:px-5">
            <img
              src="/favicon.png"
              alt="DONKEYCOMM"
              className="block mb-5 lg:hidden max-h-8"
            />
            <img
              src="/favicon.png"
              alt="DONKEYCOMM"
              className="hidden mb-5 lg:block max-h-16"
            />
            {user?.firstname && (
              <p
                className="hidden mb-3 text-sm leading-6 text-indigo-700 lg:block"
                aria-hidden="true"
              >
                Welcome {user?.firstname}
              </p>
            )}
          </div>

          <nav>
            <ul role="list grid gap-7">
              {navigation.map((item) => (
                <li key={item.name}>
                  <a
                    onClick={() => navigate(item.href)}
                    className={`${
                      activePage == item.name.toLowerCase()
                        ? 'bg-gray-50 text-indigo-600'
                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50'
                    }
                    cursor-pointer  group flex gap-x-3 md:px-5 rounded-tr-md rounded-br-md p-2 text-sm leading-6 font-semibold items-center`}
                  >
                    <Icon iconName={item.icon} />
                    <span className="hidden lg:block">{item.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="mt-10 bottom">
          <div className="grid items-center ">
            <div className="hidden my-5 border-t border-b lg:block">
              <ContactInfo />
            </div>

            <a
              onClick={logout}
              className={`cursor-pointer text-gray-700 hover:text-indigo-600 hover:bg-gray-50
                      group  gap-x-3 md:px-5 rounded-tr-md rounded-br-md p-2 text-sm leading-6 font-semibold flex items-center`}
            >
              <Icon iconName={`logout`} />
              <span className="hidden lg:block">Logout</span>
            </a>
          </div>
        </div>
      </div>
    )
  );
};
export default Navigation;
