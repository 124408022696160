import type { FC } from 'react';
import React, { useState, useContext } from 'react';
import AppContext from '../utils/appContext';
import { useNavigate } from 'react-router-dom';

interface createFolderProps {
  folderPath: string;
  updateFolderPath: (path: string) => void;

  organisations: Array<any>;
}

const CreateFolder: FC<createFolderProps> = ({
  folderPath,
  updateFolderPath,

  organisations,
}) => {
  const [loading, setLoading] = useState(false);
  const [folderName, setFolderName] = useState('');

  const [selectedOrganisations, setSelectedOrganisations] = useState<any>([]);
  const [folderError, setFolderError] = useState('');
  const [folderSuccess, setFolderSuccess] = useState('');

  const context = useContext(AppContext);
  const navigate = useNavigate();
  const sendUpFolderPath = (path: string) => {
    updateFolderPath(path);
  };

  const createFolder = async () => {
    setLoading(true);
    setFolderError('');
    setFolderSuccess('');
    const storedJWT = localStorage.getItem('jwt');
    if (folderName.trim() === '') {
      setFolderError('Folder name is required');
      setLoading(false);
    } else {
      await fetch('https://client-api.donkeycomm.be/create-folder.php', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + storedJWT,
        },
        body: JSON.stringify({
          current_path: folderPath,
          folder_name: folderName,
          access_level: selectedOrganisations.join(','),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            setFolderSuccess(data.message);
            if (data.message == 'Folder created successfully.') {
              setFolderName('');
              setFolderError('');
              setLoading(false);
              setSelectedOrganisations([]);
              sendUpFolderPath(data.folder_path);
            }
          } else if (data.error) {
            setFolderError(data.error);
          } else if (data == 'Error decoding token: Expired token') {
            logout();
          }
          setLoading(false);
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          setLoading(false);
        });
    }
  };

  const logout = () => {
    localStorage.clear();
    context?.updateLoginStatus(false);
    navigate('/login');
  };

  return (
    <div className="inline-block pr-6 mt-5 bg-white md:rounded-lg md:shadow-md md:p-6">
      <h1 className="mb-4 text-xl font-bold text-gray-800">Create folder</h1>
      <input
        className="block w-full px-2 py-1 text-gray-700 border border-gray-300 rounded-md"
        type="text"
        name="folder-name"
        placeholder="Folder name"
        value={folderName}
        onChange={(e) => setFolderName(e.target.value)}
      />

      <div className="mt-4 mb-6">
        <label
          htmlFor="access-level"
          className="block mb-2 text-sm font-medium text-gray-700"
        >
          Organisations:
        </label>
        <div className="mt-1">
          <select
            id="organisations"
            name="organisations"
            required
            multiple
            value={selectedOrganisations}
            onChange={(event) => {
              // convert selected options to an array and update state
              const selectedOptions = Array.from(
                event.target.selectedOptions,
                (option) => option.value
              );
              setSelectedOrganisations(selectedOptions);
            }}
            className={`text-default block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
          >
            <option disabled value="">
              Select organisations
            </option>

            {Array.isArray(organisations) &&
              organisations.map((organisation) => (
                <option key={organisation.ID} value={organisation.ID}>
                  {organisation.name}
                </option>
              ))}
          </select>
        </div>
      </div>
      {loading ? (
        <svg
          className="inline-block w-8 h-8 text-accent animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : (
        <button
          className="inline-flex justify-center w-auto px-4 py-2 mt-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={createFolder}
        >
          Create
        </button>
      )}

      <p className="mt-2 text-sm font-medium text-green-600">{folderSuccess}</p>
      <p className="mt-2 text-sm font-medium text-red-600">{folderError}</p>
    </div>
  );
};

export default CreateFolder;
