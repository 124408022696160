import type { FC } from 'react';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import AppContext from '../utils/appContext';

//make an infterface for the props
interface HomeProps {}
const Home: FC<HomeProps> = ({}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const checkStatus = async () => {
    const storedJWT = localStorage.getItem('jwt');

    if (storedJWT) {
      await fetch('https://client-api.donkeycomm.be/check-status.php', {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + storedJWT,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data == 'success') {
            console.log('success');
            setShow(true);
          } else {
            localStorage.clear();
            context?.updateLoginStatus(false);
            navigate('/login');
          }
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          localStorage.clear();
          context?.updateLoginStatus(false);
          navigate('/login');
        });
    } else {
      console.log('not logged in');
      localStorage.clear();
      context?.updateLoginStatus(false);
      navigate('/login');
    }
  };
  useEffect(() => {
    checkStatus();
  }, []);

  const roles = JSON.parse(user.organisations || '[]');

  return (
    show && (
      <>
        {loading ? (
          <div className="grid content-center justify-center py-32">
            <svg
              className="inline-blockmt-5 w-9 h-9 text-accent animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        ) : (
          //create a nice welcome page in tailwind
          <div className="grid max-w-lg mx-auto mt-10 lg:h-full lg:content-center md:mt-0">
            <h1 className="text-xl font-bold tracking-tight text-gray-700 md:text-4xl sm:text-3xl">
              DONKEYCOMM Client Zone
            </h1>
            <p className="mt-3 text-sm text-gray-600 md:mt-6 md:leading-8 md:text-lg">
              Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui
              lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat
              fugiat aliqua.
            </p>
            <div className="flex items-center mt-10 md:mt-10 gap-x-6">
              <a
                onClick={() => navigate('/files')}
                className="rounded-md cursor-pointer transition bg-indigo-600 px-3 py-2 md:px-3.5 md:py-2.5 text-sm  text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Go to files
              </a>

              {(roles.includes(0) || roles.includes(1)) && (
                <a
                  onClick={() => navigate('/members')}
                  className="text-sm font-semibold leading-6 text-gray-700 cursor-pointer hover:text-indigo-600"
                >
                  Add members <span aria-hidden="true">→</span>
                </a>
              )}
            </div>
          </div>
        )}
      </>
    )
  );
};
export default Home;
