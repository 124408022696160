import type { FC } from 'react';
import React from 'react';
import Navigation from '../components/navigation';
import ContactInfo from '../components/contactinfo';
//make an infterface for children props
interface LayoutProps {
  children: React.ReactNode;
}
const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <main className="md:py-5 md:py-10 md:px-0 lg:px-10 xl:px-32">
      <div className="mx-auto ">
        <div className="flex justify-start px-2 py-5 rounded-lg shadow md:m-2 md:py-10 md:pr-5 md:m-5 lg:pr-10">
          <div className="lg:max-w-[250px] lg:w-[250px]">
            <Navigation />
          </div>
          <div className="w-full pl-5 overflow-x-hidden md:pl-5 lg:pl-10 xl:pl-20">
            {children}
            <div className="py-12 lg:hidden">
              <ContactInfo />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default Layout;
