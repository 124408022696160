import type { FC } from 'react';
import { useEffect, useState } from 'react';
interface folderitemProps {
  view: string;
  folder: any;
  selectFolder: (path: string) => void;
  organisations: Array<any>;
}

const FolderItem: FC<folderitemProps> = ({
  view,
  folder,
  selectFolder,
  organisations,
}) => {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [hideItem, setHideItem] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );
  const [visibility, setVisibility] = useState<Array<number>>([0, 1]);

  const deleteFolder = async (id: number) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this file?'
    );
    if (!confirmDelete) {
      return;
    }
    setLoadingDelete(true);

    const storedJWT = localStorage.getItem('jwt');

    await fetch('https://client-api.donkeycomm.be/delete-folder.php', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + storedJWT,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          alert(data.error);

          setLoadingDelete(false);
        } else {
          setHideItem(true);
        }
      })
      .catch((error) => {
        // Handle network or server errors
        console.log('error');
        console.log(error);
        alert(error);
        setLoadingDelete(false);
      });
  };

  const date = new Date(folder.date);
  const readableDate = date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  useEffect(() => {
    const vis_array = JSON.parse(folder.access_level);
    setVisibility(vis_array);
  }, []);
  const roles = JSON.parse(user.organisations || '[]');

  return (
    !hideItem && (
      <div
        className={`
           ${
             view == 'list'
               ? 'w-full h-auto px-2 mb-2 md:mb-0 md:flex justify-between  items-center xl:hover:bg-slate-100 rounded-md transition '
               : ' grid items-end  w-[200px] h-auto '
           } 
          
            max-w-[100%] relative overflow-hidden group `}
        key={folder.ID}
      >
        <div className={`${view == 'list' && 'flex mb-2 md:mb-0'}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            viewBox="0 0 256 250"
            xmlSpace="preserve"
            className={` ${
              view === 'list' ? ' min-w-[47px] w-12 h-12' : 'w-32 h-30'
            } mr-3 md:mr-5  fill-indigo-700 transition-fill cursor-pointer`}
            onClick={() => selectFolder(folder.path)}
          >
            <defs></defs>
            <g
              style={{
                stroke: 'none',
                strokeWidth: 0,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 10,
                fill: 'none',
                fillRule: 'nonzero',
                opacity: 1,
              }}
              transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
            >
              <path
                d="M 3.649 80.444 h 82.703 c 2.015 0 3.649 -1.634 3.649 -3.649 v -56.12 c 0 -2.015 -1.634 -3.649 -3.649 -3.649 H 35.525 c -1.909 0 -3.706 -0.903 -4.846 -2.435 l -2.457 -3.301 c -0.812 -1.092 -2.093 -1.735 -3.454 -1.735 H 3.649 C 1.634 9.556 0 11.19 0 13.205 v 63.591 C 0 78.81 1.634 80.444 3.649 80.444 z"
                style={{
                  stroke: 'none',
                  strokeWidth: 1,
                  strokeDasharray: 'none',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: 10,
                  fill: 'rgb(146,220,255)',
                  fillRule: 'nonzero',
                  opacity: 1,
                }}
                transform=" matrix(1 0 0 1 0 0) "
                strokeLinecap="round"
              />
              <path
                d="M 86.351 80.444 H 3.649 C 1.634 80.444 0 78.81 0 76.795 V 29.11 c 0 -2.015 1.634 -3.649 3.649 -3.649 h 82.703 c 2.015 0 3.649 1.634 3.649 3.649 v 47.685 C 90 78.81 88.366 80.444 86.351 80.444 z"
                style={{
                  stroke: 'none',
                  strokeWidth: 1,
                  strokeDasharray: 'none',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: 10,
                  fill: 'rgb(101,202,248)',
                  fillRule: 'nonzero',
                  opacity: 1,
                }}
                transform=" matrix(1 0 0 1 0 0) "
                strokeLinecap="round"
              />
              <path
                d="M 85.106 76.854 H 4.894 c -0.276 0 -0.5 -0.224 -0.5 -0.5 s 0.224 -0.5 0.5 -0.5 h 80.213 c 0.276 0 0.5 0.224 0.5 0.5 S 85.383 76.854 85.106 76.854 z"
                style={{
                  stroke: 'none',
                  strokeWidth: 1,
                  strokeDasharray: 'none',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: 10,
                  fill: 'rgb(146,220,255)',
                  fillRule: 'nonzero',
                  opacity: 1,
                }}
                transform=" matrix(1 0 0 1 0 0) "
                strokeLinecap="round"
              />
              <path
                d="M 85.106 72.762 H 4.894 c -0.276 0 -0.5 -0.224 -0.5 -0.5 s 0.224 -0.5 0.5 -0.5 h 80.213 c 0.276 0 0.5 0.224 0.5 0.5 S 85.383 72.762 85.106 72.762 z"
                style={{
                  stroke: 'none',
                  strokeWidth: 1,
                  strokeDasharray: 'none',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: 10,
                  fill: 'rgb(146,220,255)',
                  fillRule: 'nonzero',
                  opacity: 1,
                }}
                transform=" matrix(1 0 0 1 0 0) "
                strokeLinecap="round"
              />
            </g>
          </svg>

          <div
            className={`${
              view == 'list' ? 'flex overflow-hidden max-w-full  ' : 'mb-2'
            } flex items-center `}
          >
            <p
              className={`relative text-default text-sm overflow-hidden overflow-ellipsis max-h-[37px] md:max-h-auto`}
            >
              {folder.name}
            </p>
          </div>
        </div>
        <div className={`${view == 'list' && 'flex'}`}>
          {(roles.includes(0) || roles.includes(1)) && (
            <>
              {loadingDelete ? (
                <svg
                  className={`${
                    view !== 'list' && 'absolute top-2 left-2'
                  } text-white w-7 h-7 animate-spin hidden xl:group-hover:inline-block`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25 "
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#ffffff"
                  className={` ${
                    view == 'list' ? 'mr-5' : 'absolute top-2 left-2'
                  } p-1 transition-colors hidden bg-opacity-50 rounded-md cursor-pointer w-7 h-7  bg-slate-700 hover:bg-red-700 xl:group-hover:inline-block`}
                  onClick={() => deleteFolder(folder.ID)}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              )}

              {loadingDelete ? (
                <svg
                  className={`${
                    view !== 'list' && 'absolute blocktop-2 left-2'
                  } text-white h-5 w-5 md:w-7 md:h-7 animate-spin`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25 "
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#ffffff"
                  className={` ${
                    view == 'list' ? 'mr-5' : 'absolute top-2 left-2'
                  }   p-1 transition-colors  xl:hidden   bg-opacity-50 rounded-md cursor-pointer h-6 w-6 md:w-7 md:h-7  bg-slate-700 hover:bg-red-700`}
                  onClick={() => deleteFolder(folder.ID)}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              )}

              {visibility.length > 1 && view !== 'list' && (
                <div className="relative flex flex-wrap gap-1 mb-2 mr-5">
                  {visibility
                    .filter((vis) => vis !== 0 && vis !== 1)
                    .map((vis: number) => {
                      const organisation = organisations?.find(
                        (org) => org.ID === vis
                      );
                      return (
                        <p
                          className="px-2  py-0.5 text-xs text-white rounded-md bg-opacity-60 bg-slate-700"
                          key={vis}
                        >
                          {organisation?.name}
                        </p>
                      );
                    })}
                </div>
              )}
              {visibility.length > 1 && view === 'list' && (
                <div className="relative flex flex-wrap content-center gap-1 mr-5">
                  {visibility
                    .filter((vis) => vis !== 0 && vis !== 1)
                    .map((vis: number) => {
                      const organisation = organisations?.find(
                        (org) => org.ID === vis
                      );
                      return (
                        <div key={vis}>
                          <p className="px-2 py-0.5 text-xs text-white rounded-md bg-slate-600">
                            {organisation?.name}
                          </p>
                        </div>
                      );
                    })}
                </div>
              )}
            </>
          )}

          <p
            className={`relative ${
              view == 'list' && 'hidden lg:grid content-center'
            }
            text-xs text-gray-500`}
          >
            {readableDate}
          </p>
        </div>
      </div>
    )
  );
};
export default FolderItem;
