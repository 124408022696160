import type { FC } from 'react';
import { useState } from 'react';
interface fileitemProps {
  view: string;
  file: any;
}

const BinItem: FC<fileitemProps> = ({ view, file }) => {
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [hideItem, setHideItem] = useState(false);
  //check file extension
  const fileExtension = file.file_name.split('.').pop();

  const getPlaceholder = (fileExtension: string): string => {
    const extensionToPlaceholder: { [key: string]: string } = {
      mp4: '/placeholder-video.svg',
      mov: '/placeholder-video.svg',
      avi: '/placeholder-video.svg',
      wmv: '/placeholder-video.svg',
      doc: '/placeholder-word.svg',
      docx: '/placeholder-word.svg',
      xls: '/placeholder-excel.svg',
      xlsx: '/placeholder-excel.svg',
      svg: '/placeholder-svg.svg',
      pdf: '/placeholder-pdf.svg',
      eps: '/placeholder-eps.svg',
      ai: '/placeholder-illustrator.svg',
      psd: '/placeholder-photoshop.svg',
      indd: '/placeholder-indesign.svg',
    };

    return extensionToPlaceholder[fileExtension] || 'placeholder-document.svg';
  };

  let placeholder = getPlaceholder(fileExtension);

  const downloadFile = async (id: number, filename: string) => {
    setLoadingDownload(true);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');
    user.email &&
      (await fetch(
        'https://client-api.donkeycomm.be/download-file-from-trash.php',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + storedJWT,
          },
          body: JSON.stringify({
            id: id,
            email: user.email,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              'Network response was not ok ' + response.statusText
            );
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          setLoadingDownload(false);
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          alert(error);
          setLoadingDownload(false);
        }));
  };
  const deleteFile = async (id: number) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this file?'
    );
    if (!confirmDelete) {
      return;
    }
    setLoadingDelete(true);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');
    user.email &&
      (await fetch('https://client-api.donkeycomm.be/delete-file.php', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + storedJWT,
        },
        body: JSON.stringify({
          id: id,
          email: user.email,
        }),
      })
        .then((response) => {
          if (response.status == 200) {
            setHideItem(true);
          }
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          alert(error);
          setLoadingDelete(false);
        }));
  };
  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };
  //create a readable date from file.date
  const date = new Date(file.date);
  const readableDate = date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  return (
    !hideItem && (
      <div
        className={`
           ${
             view === 'list'
               ? 'w-full px-2 mb-5 md:mb-0 py-3 md:py-5 xl:py-0 border-b xl:border-none h-auto xl:flex xl:hover:bg-slate-100 transition justify-between max-w-full overflow-hidden  '
               : 'border-gray-100 border rounded-md grid content-end items-end  p-3'
           } 
            ${
              view == 'small' && 'w-[180px] h-[180px] lg:w-[200px] lg:h-[200px]'
            } 
            ${
              view == 'medium' &&
              'w-[290px] h-[290px] lg:w-[400px] lg:h-[400px]'
            }
            ${view == 'large' && 'w-full h-[250px] md:h-[600px]'}
            max-w-[100%] relative overflow-hidden group`}
        key={file.ID}
      >
        <div
          className={`${
            view !== 'list' ? 'mb-2' : 'md:p-1 mb-4 md:mb-0'
          } md:rounded-md  flex`}
        >
          {file.base64 ? (
            <img
              src={`data:image/jpeg;base64,${file.base64}`}
              alt={file.file}
              className={`${
                view == 'list'
                  ? 'h-10 w-10 inline-block object-contain mb-1 md:mb-0 mr-3 object-left'
                  : 'w-full h-full  absolute top-0 left-0 object-cover'
              } 
          `}
            />
          ) : (
            <img
              src={placeholder}
              alt={file.file}
              className={`${
                view == 'list'
                  ? 'h-10 w-10 inline-block mr-3 md:mr-5 object-contain object-left'
                  : 'w-full h-full object-scale-down absolute top-0 left-0 '
              }  
      `}
            />
          )}
          {view !== 'list' && (
            <div className="absolute top-0 left-0 w-full h-full transition-opacity opacity-50 bg-gradient-to-t via-transparent from-black group-hover:opacity-0" />
          )}
          <div className="grid items-center">
            <p
              className={`relative ${
                view == 'list'
                  ? 'text-default pr-12 max-h-[42px]  md:max-h-auto  '
                  : 'text-white '
              }
            ${view == 'small' ? 'text-xs' : 'text-sm'}
             pr-5 overflow-hidden`}
            >
              {file.file_name}
            </p>
          </div>
        </div>

        <div
          className={`${
            view !== 'list'
              ? 'flex justify-between'
              : 'flex items-center align-right'
          } `}
        >
          {loadingDelete ? (
            <svg
              className={` ${
                view !== 'list' ? 'absolute left-3 top-3' : 'hidden xl:block'
              } text-white absolute w-7 h-7 animate-spin mr-5`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#ffffff"
              className={` ${
                view !== 'list'
                  ? 'absolute left-3 top-3 xl:hidden xl:group-hover:inline-block'
                  : 'hidden xl:group-hover:inline-block'
              }   p-1 transition-colors mr-5  bg-opacity-50 rounded-md cursor-pointer w-7 h-7  bg-slate-700 hover:bg-red-700 `}
              onClick={() => deleteFile(file.ID)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          )}
          <p
            className={`relative ${
              view == 'list'
                ? 'hidden md:block text-gray-500 pr-12 text-xs'
                : ' text-white '
            }
            ${view == 'small' ? 'text-xs' : 'text-sm'}
             pr-5`}
          >
            {readableDate}
          </p>
          <p
            className={`relative ${
              view == 'list'
                ? 'hidden text-xs xl:text-sm md:block text-gray-500 pr-5'
                : 'text-white '
            }
            ${view == 'small' ? 'text-xs' : 'text-sm'}
           `}
          >
            {formatBytes(file.size)}
          </p>
          {loadingDelete ? (
            <svg
              className={`xl:hidden text-white absolute h-6 w-6 md:w-7 md:h-7 animate-spin mr-5`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#ffffff"
              className={`xl:hidden p-1 transition-colors mr-5  bg-opacity-50 rounded-md cursor-pointerh-6 w-6 md:w-7 md:h-7   bg-slate-700 hover:bg-red-700 `}
              onClick={() => deleteFile(file.ID)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          )}

          {loadingDownload ? (
            <svg
              className="w-6 h-6 md:w-7 md:h-7 text-accent animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#ffffff"
              className={`${
                view !== 'list' && 'absolute right-3 top-3'
              }  inline-block p-1 transition-colors bg-opacity-50 rounded-md cursor-pointer h-6 w-6 md:w-7 md:h-7   bg-slate-700 hover:bg-indigo-700`}
              onClick={() => downloadFile(file.ID, file.file_name)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>
          )}
        </div>
      </div>
    )
  );
};
export default BinItem;
