import type { FC } from 'react';
import React, { useEffect, useContext } from 'react';
import AppContext from '../utils/appContext';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import FileUpload from '../components/fileupload';
import CreateFolder from '../components/createfolder';
import FileBrowser from '../components/filebrowser';

//files interface
interface filesProps {}
const Files: FC<filesProps> = ({}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );
  const [folderPath, setFolderPath] = useState<string>('/');
  const [counter, setCounter] = useState(0);
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [organisations, setOrganisations] = useState<Array<any>>([]);
  const checkStatus = async () => {
    const storedJWT = localStorage.getItem('jwt');

    if (storedJWT) {
      await fetch('https://client-api.donkeycomm.be/check-status.php', {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + storedJWT,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data == 'success') {
            console.log('success');
            setLoading(false);
          } else {
            localStorage.clear();
            context?.updateLoginStatus(false);
            navigate('/login');
          }
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          localStorage.clear();
          context?.updateLoginStatus(false);
          navigate('/login');
        });
    } else {
      console.log('not logged in');
      localStorage.clear();
      context?.updateLoginStatus(false);
      navigate('/login');
    }
  };

  const updateFolder = () => {
    console.log('update folder');
    setCounter((counter) => counter + 1);
  };

  const updateFolderPath = (path: string) => {
    setFolderPath(path);
  };
  const getOrganisations = async () => {
    const storedJWT = localStorage.getItem('jwt');
    await fetch('https://client-api.donkeycomm.be/get-all-organisations.php', {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + storedJWT,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setOrganisations(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if (user.organisations?.includes(0) || user.organisations?.includes(1)) {
      getOrganisations();
    }
    checkStatus();
  }, []);
  const roles = JSON.parse(user.organisations || '[]');

  return (
    <>
      <FileBrowser
        folderPath={folderPath}
        changeFolderPath={updateFolderPath}
        refreshCounter={counter}
        organisations={organisations}
      />
      <div className="grid max-w-3xl gap-10 mb-10 lg:grid-cols-2 md:mb-5">
        {(roles.includes(0) || roles.includes(1)) && (
          <FileUpload
            updateFolder={updateFolder}
            folderPath={folderPath}
            organisations={organisations}
          />
        )}
        {(roles.includes(0) || roles.includes(1)) && (
          <CreateFolder
            updateFolderPath={updateFolderPath}
            folderPath={folderPath}
            organisations={organisations}
          />
        )}
      </div>
    </>
  );
};
export default Files;
